/*start of global styles */
.main {
    background-color: #FAFAFA;
    height: 100vh;
}

.greetingText {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.greetingText h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #006083;
    width: 80%;
}

.secondaryText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #006083;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
}

.signature-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.sigCanvas {
    width: 280px;
    height: 300px;
    margin: 10px auto;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: block;
}

.sigTexT {
    max-width: 300px;
    margin: 10px auto;
    display: block;
}

.voteAgenda {
    background: linear-gradient(173.16deg, #42AECC -33.81%, #006083 65.4%);
    opacity: 0.05;
    border-radius: 15px 15px 0 0;
}

.headGris {
    /* background: linear-gradient(173.16deg, #42AECC -33.81%, #006083 65.4%); //TODO: problem with opacity #eef3f4 */
    background: #eef3f4;
    /* opacity: 0.05; */
    border-radius: 15px 15px 0 0;
    min-height: 150px;
    padding: 20px;
    margin-top: 20px;

}

.boxShadow {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
}

.userVote {
    width: 61px;
}

.voteOptions {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.voteOptions > div {
    width: 200px;
}

.voteSteps {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}

.vote-header {
    /* background: linear-gradient(173.16deg, #42AECC -33.81%, #006083 65.4%); //TODO: problem with opacity #eef3f4 */
    /*background: #eef3f4;*/
    /* opacity: 0.05; */
    /*min-height: 150px;*/
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vote-header h1 {
    margin-left: 30px;
}

.vote-header .meeting-point-block {
    width: 100%;
    padding: 25px 50px;
}

.vote-mandant-name {
    color: #006083;
    font-weight: 700;
}

.vote-mandant-choices li {
    position: relative;
}

.vote-mandant-choices li+li:before {
    content: '';
    display: block;
    height: 1px;
    width: 85px;
    background-color: #006083;
    position: absolute;
    top: 0;
    left: 56px;
}

.bg-gris {
    background-color: #FAFAFA;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.hideBg {
    margin-left: calc(var(--bs-gutter-x) * -.5);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    background-color: #FAFAFA;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 80vh;
}

.canvasDisplay {
    width: 280px;
    height: 200px;
    margin: 10px auto;
}

/*
helper classes*/

h1 {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #006083;
}

p {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem; /*22px*/
}


.rotate {
    transform: rotate(90deg);
}

.fileDisplay {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgb(0 0 0 / 5%);
    border-radius: 15px;
    display: flex;
    min-height: 85px;
    align-content: space-around;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-direction: row;
    padding: 5px;
    cursor: pointer;
}

.fileDisplay h1 {
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileDisplay.altDisplay {
    background-color: #006083;
    color: #FFFFFF;
    max-width: 258px;
    padding-right: 10px;
    transition: background-color 0.3s ease-in-out;
}

.fileDisplay.altDisplay:hover {
    background-color: rgba(0, 96, 131, 0.9);
}

.fileDisplay.altDisplay svg {
    fill: currentColor;
    flex: 1 0 auto;
}

.fileDisplayText {
    margin-left: 15px;
}

.blockFiles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    /*flex-direction: row;*/
}

.fileDisplay + .fileDisplay {
    margin-left: 10px;
}

.freeDiscussion {
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
}

.freeDiscussion p {
    color: #525252;
}

.free-discussion-list__item {
    position: relative;
    padding-left: 40px;
}

.free-discussion-list__item:before,
.free-discussion-list__item:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #006083;
}

.free-discussion-list__item:before {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    top: 6px;
    left: 0;
}

.free-discussion-list__item:after {
    width: 1px;
    left: 4px;
    top: 15px;
    height: 150%;
}

.free-discussion-list__item:last-child:after {
    display: none;
}

/*.styled-card h2 {
    color: #006083;
    font-size: 16px;
}*/

.boxRes {
    background: #FFFFFF;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    margin: 10px 5px;
    padding: 25px;
    position: relative;
}

.boxRes .aea-svg {
    position: absolute;
    right: 5px;
    top: -5px;
    fill: #6FCF97;
    width: 20px;
    margin-top: -10px;
}

.meeting-meta {
    font-size: 0.875rem;
}

.signature-component__btn-delete {
    color: #006083;
}

.signature-component__btn-confirm.button-styled {
    width: auto;
    margin: 10px auto;
}

.prevNextStep {
    display: flex;
    justify-content: space-between;
}

.prevNextStep .next-step-btn {
    margin-left: auto;
}

button {
    border: none;
    background-color: transparent;
}

/*end of global styles*/


/*https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width*/
@media only screen and (max-width: 991.98px) {
    .greetingText h1 {
        color: #FFFFFF;
    }

    .vote-header {
        font-weight: normal;
        color: #FFFFFF;
        margin-top: 20px;
        padding: 20px;
    }

    .vote-header h1 {
        color: #FFFFFF;
        margin-left: 25px;
    }

    .vote-header p {
        font-size: 16px;
        line-height: 22px;
    }

    .vote-header h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }

    .vote-header svg {
        fill: #FFFFFF;
    }

    .bgBleuMobile {
        background-image: linear-gradient(173.16deg, #42AECC -33.81%, #006083 65.4%);
    }

    .boxShadow {
        background: none;
        box-shadow: none;
    }

    .boxShadow p {
        margin: 15px;
        text-align: justify;
    }

    .vote-backTo-block {
        text-align: right;
        padding-right: 15px;
    }

    .back-to-link--from-meeting {
        color: #FFFFFF;
    }

    .free-discussion-list__item {
        padding-left: 25px;
    }

    .signature-header {
        text-align: center;
        width: 100%;
        justify-content: center;
        padding: 0 60px;
    }

    .secondaryText {
        justify-content: center;
    }

    .signature-component__btn-confirm.button-styled {
        width: 100%;
    }

    .mobile-padding-space {
        padding: 15px;
    }

    .vote-step-1 .prevNextStep .next-step-btn {
        width: 100%;
        justify-content: center;
    }
}

/*end of 'Mobile only'*/

.disabledBtn {
    cursor: not-allowed !important;
    background-color: #959595 !important;
}
